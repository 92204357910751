import { displayModal} from './uiDisplayer'

const areCookiesEnabled = () => (typeof navigator === 'object' || navigator.cookieEnabled);

const isIndexedDBAvailable = () => {
	try {
		return typeof indexedDB === 'object';
	} catch (e) {
		return false;
	}
}

export const validateIndexedDBOpenable = () => {
	return new Promise((resolve, reject) => {
		try {
			let preExist = true;
			const DB_CHECK_NAME = 'validate-browser-context-for-indexeddb-analytics-module';
			const request = self.indexedDB.open(DB_CHECK_NAME);
			request.onsuccess = () => {
				request.result.close();
				if (!preExist) {
						self.indexedDB.deleteDatabase(DB_CHECK_NAME);
				}
				resolve(true);
			};
			request.onupgradeneeded = () => {
				preExist = false;
			};
			request.onerror = () => {
				var _a;
				reject(((_a = request.error) === null || _a === void 0 ? void 0 : _a.message) || '');
			};
		}
		catch (error) {
			reject(error);
		}
	});
}

export const isPWASupported = () => {
	return(
		typeof window !== 'undefined' &&
		isIndexedDBAvailable() &&
		areCookiesEnabled() &&
		'serviceWorker' in navigator &&
		'PushManager' in window &&
		'Notification' in window &&
		'fetch' in window &&
		ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
		PushSubscription.prototype.hasOwnProperty('getKey') &&
		!navigator.brave
	);
}

export const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches || getCookie('pcs_dk');

export const isMobile = () => window.innerWidth < 768;

export const isAndroidInAppBrowser = () => (/Android.*(NAVER|KAKAOTALK|Whale|DaumApps|FB_IAB)/).test(navigator.userAgent);

export const setCookie = (name, value, days, path = '/') => {
	let expires = '';

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = date.toGMTString();
	}

	document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
}

export const getCookie = (name) => {
	return document.cookie.split('; ').reduce((r, v) => {
		const cookie = v.split('=');
		return cookie[0] === name ? decodeURIComponent(cookie[1]) : r;
	}, '');
}

export const destroyCookie = (name) => {
  setCookie(name, '', -1);
}

export const throttle = (callback, time) => {
	let timerId;
	if (timerId) return;
	timerId = setTimeout(() => {
		callback();
		timerId = null;
	}, time);
}

export const throttleCallback = (callback) => {
	let ticking = false;
	if (!ticking) {
		window.requestAnimationFrame(() => {
			callback();
			ticking = false;
		});
		ticking = true;
	}
}

export const generateCustomAlert = (msg) => {
	if (document.querySelector('.custom-alert')) return;

	const customAlert = document.createElement('div');
	customAlert.innerHTML = `
		<div class='custom-alert'>
			<div class='circle-with-check'></div>
			<p class='msg'>${msg}</p>
			<button name='button' type='button' class='confirm'>확인</button>
		</div>
	`;
	document.body.appendChild(customAlert);
	document.querySelector('button.confirm').addEventListener('click', () => {
		document.body.style = 'overflow: visible; pointer-events: auto';
		customAlert.remove();
	});
}

export const popup = (url, title, w = 600, h = 600) => {
	const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
	const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

	const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

	const systemZoom = width / window.screen.availWidth;
	const left = (width - w) / 2 / systemZoom + dualScreenLeft;
	const top = (height - h) / 2 / systemZoom + dualScreenTop;
	const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

	if (window.focus) newWindow.focus();
}

export const downloadFile = async (requestUrl) => {
	//Loader.show()

	try {
		const response = await fetch(requestUrl)
		const contentDisposition = response.headers.get('Content-Disposition')

		let filename = ''
		const filenameRegex = /filename\*=UTF-8''([^;]*)/
		const match = contentDisposition.match(filenameRegex)
		if (match && match[1]) {
			filename = decodeURIComponent(match[1])
		}

		const blob = await response.blob()
		const url = window.URL.createObjectURL(blob)
		const a = document.createElement('a')

		a.href = url
		a.download = filename

		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)

		window.URL.revokeObjectURL(url)
	} catch (error) {
		console.log(error)
	} finally {
		//Loader.hide()
	}
}

export const openWindow = (url, w = 600, h = 600) => {
	const left = window.top.outerWidth / 2 + window.top.screenX - ( w / 2)
	const __top = window.top.outerHeight / 2 + window.top.screenY - ( h / 2)
	const popupWindow = window.open(url, url, `scrollbars=yes, width=${w}, height=${h}, top=${__top}, left=${left}`)

	if (window.focus) popupWindow.focus()

	return popupWindow
}

export const previewFile = async (requestUrl) => {
	const response = await fetch(requestUrl)
	const data = await response.json()
	openWindow(data.fileUrl, screen.availWidth, screen.availHeight)
}

export const postData = async (url = '', data = {}) => {
	const response = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
		},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(data)
	});

	return response.json();
}




document.addEventListener("DOMContentLoaded", function () {
	function bindImageEvents() {
		document.querySelectorAll(".content img").forEach(img => {
			img.style.cursor = "zoom-in";
			img.addEventListener("click", function () {
				const modal = document.getElementById("photo-viewer");
				const modalImg = modal.querySelector("img");
				const caption = modal.querySelector("figcaption");

				modal.style.display = "flex";
				modalImg.src = this.src;
				const figcaptionText = this.closest("figure")?.querySelector("figcaption")?.innerText || "이미지 확대 보기";
				caption.innerText = figcaptionText;
			});
		});
	}

	// 버튼 닫힘
	document.querySelector(".fn.close").addEventListener("click", function () {
		document.getElementById("photo-viewer").style.display = "none";
	});

	// 여백 닫힘
	document.getElementById("photo-viewer").addEventListener("click", function (event) {
		if (event.target === this) {
			this.style.display = "none";
		}
	});

	bindImageEvents();
	const observer = new MutationObserver(bindImageEvents);
	const targetNode = document.querySelector(".content");

	if (targetNode) {
		observer.observe(targetNode, { childList: true, subtree: true });
	}
});
//

// document.addEventListener("DOMContentLoaded", function () {
// 	function bindImageEvents() {
// 		document.querySelectorAll(".content img").forEach(img => {
//
// 			if (!img.dataset.zoomBound) {
// 				img.style.cursor = "zoom-in";
// 				img.addEventListener("click", function () {
// 					const modal = document.getElementById("photo-viewer");
// 					const modalImg = modal.querySelector("figure img");
// 					const caption = modal.querySelector("figcaption");
//
//
// 					modal.style.display = "flex";
// 					modalImg.src = this.src;
// 					caption.innerText = this.alt || "이미지 확대 보기";
// 				});
// 				img.dataset.zoomBound = "true"; // 중복 방지용 데이터 속성 추가
// 			}
// 		});
// 	}
//
// 	document.querySelector(".fn.close").addEventListener("click", function () {
// 		document.getElementById("photo-viewer").style.display = "none";
// 	});
//
// 	document.getElementById("photo-viewer").addEventListener("click", function (event) {
// 		if (event.target === this) {
// 			this.style.display = "none";
// 		}
// 	});
//
// 	bindImageEvents();
//
// 	const observer = new MutationObserver(() => {
// 		bindImageEvents();
// 	});
// 	const targetNode = document.querySelector(".content");
//
// 	if (targetNode) {
// 		observer.observe(targetNode, { childList: true, subtree: true });
// 	}
// });


